import React, { useEffect } from "react";
import HeaderThree from "../Layouts/Headers/HeaderFour";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import MainWid from "./MainWid";
import FooterOne from "../Layouts/Footers/FooterOne";

function Contact() {
  useEffect(() => {
    document.title = "Contact - D&A Friends Painting LLC";
  }, []);

  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Contact Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "Contact", path: "/contact" },
        ]}
      />
      <MainWid />
      <FooterOne />
    </>
  );
}

export default Contact;
