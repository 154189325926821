import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderFour";
import InnerPageTitle from "../../Helpers/InnerPageTitle";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid from "./MainWid";
import BrandAreaWid from "../../Common/BrandAreaWid";

function ServiceDetails() {
  return (
    <>
      <HeaderThree />
      <main>
        <InnerPageTitle
          title="Painting Service Details"
          paths={[
            { name: "Home", path: "/" },
            { name: "Services", path: "/services" },
            { name: "Painting Service Details", path: "/services/services-details" },
          ]}
        />
        <MainWid />
        <BrandAreaWid className="pt-0" />
      </main>
      <FooterOne />
    </>
  );
}

export default ServiceDetails;
