import React from "react";

function WorkArea() {
  return (
    <section className="work-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Working Plan</span>
              <h2 className="title">Plan Working Process</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img01.png`)}
                  alt=""
                />
                <h4 className="number">01</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Plan for Work</h2>
                <p>
                We carefully plan the project to ensure all details are addressed and your needs are met.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img02.png`)}
                  alt=""
                />
                <h4 className="number">02</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Schedule Estimate</h2>
                <p>
                We provide a detailed estimate and schedule the work at a time that is convenient for you.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img03.png`)}
                  alt=""
                />
                <h4 className="number">03</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Working in your Project</h2>
                <p>
                Our professional team executes the project with precision and dedication, ensuring top-quality results.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/work_img04.png`)}
                  alt=""
                />
                <h4 className="number">04</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Enjoy</h2>
                <p>
                Enjoy the beautifully finished project, delivered on time and to your satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkArea;
