import React, { useEffect } from "react";
import HeaderFour from "../Layouts/Headers/HeaderFour";
import FooterOne from "../Layouts/Footers/FooterOne";
import Hero from "./Hero";
import FeatureArea from "./FeatureArea";
import AboutArea from "../HomeTwo/AboutArea";
import ServiceArea from "./ServiceArea";

import MultipleArea from "../HomeTwo/MultipleArea";
import SupportArea from "../HomeTwo/SupportArea";
import TestimonialArea from "../About/TestimonialArea";
import BrandAreaWid from "../Common/BrandAreaWid";

function HomeFour() {
  useEffect(() => {
    document.title = "D&A Friends Painting LLC";
  }, []);

  return (
    <>
      <HeaderFour />
      <main>
        <Hero />
        <FeatureArea />
        <AboutArea />
        <ServiceArea />

        <MultipleArea />
        <SupportArea />
        <TestimonialArea className="testimonial-area-three  pt-115" />
        <BrandAreaWid />
   
      </main>
      <FooterOne />
    </>
  );
}

export default HomeFour;

