import React, { useState } from "react";

function ServiceArea() {
  const [tab, setTab] = useState(1);

  return (
    <section className="services-area-three">
      <div
        className="services-bg jarallax"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/services_bg.jpg")})`,
        }}
      ></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title text-center white-title mb-60">
              <span className="sub-title">Discover Our Company</span>
              <h2 className="title">See Our Services</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="services-nav-wrap">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${tab === 1 ? "active" : ""}`}
                    onClick={() => setTab(1)}
                    id="building-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#building-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="building-tab-pane"
                    aria-selected="true"
                  >
                    Painting
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${tab === 2 ? "active" : ""}`}
                    onClick={() => setTab(2)}
                    id="maintenance-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#maintenance-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="maintenance-tab-pane"
                    aria-selected="false"
                  >
                    Carpentry
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${tab === 3 ? "active" : ""}`}
                    onClick={() => setTab(3)}
                    id="engineer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#engineer-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="engineer-tab-pane"
                    aria-selected="false"
                  >
                    Drywall
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane ${tab === 1 ? "active show" : ""}`}
                  id="building-tab-pane"
                  role="tabpanel"
                  aria-labelledby="building-tab"
                  tabIndex="0"
                >
                  <div className="services-item-three">
                    <div className="services-thumb-three">
                      <img
                        src={require(`../../assets/img/services/services1.webp`)}
                        alt=""
                      />
                    </div>
                    <div className="services-content-three">
                      <div className="section-title mb-30">
                        <h2 className="title">
                        Transform Your Spaces: Painting in Construction Projects
                        </h2>
                      </div>
                      <p>
                      Painting in construction projects is essential for bringing 
                      life and personality to any building. With the right colors 
                      and finishes, you can create unique and appealing environments, 
                      enhancing both the visual appeal and value of the spaces, whether indoors or outdoors.
                      </p>
                      <div className="services-list">
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Interior Painting
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Exterior Painting
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Staining and Finishing
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Wallpaper Removal and Installation
                          </li>
                        </ul>
                      </div>
                      <a href="/services/services-details" className="btn">
                        Book Service
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane ${tab === 2 ? "active show" : ""}`}
                  id="maintenance-tab-pane"
                  role="tabpanel"
                  aria-labelledby="maintenance-tab"
                  tabIndex="0"
                >
                  <div className="services-item-three">
                    <div className="services-thumb-three">
                      <img
                        src={require(`../../assets/img/services/services2.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="services-content-three">
                      <div className="section-title mb-30">
                        <h2 className="title">
                        Expert Carpentry Services for Your Construction Needs

                        </h2>
                      </div>
                      <p>
                      Our expert carpentry services are designed to meet all your construction needs. 
                      With a focus on precision and quality, we craft custom structures that are both 
                      beautiful and durable. Whether you need intricate woodwork for your home or robust 
                      solutions for commercial projects, our skilled carpenters deliver exceptional results every time.
                      </p>
                      <div className="services-list">
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Kitchen and Bathroom Cabinetry
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Interior Trim and Molding Installation
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Door and Window Installation
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Flooring Installation and Repair
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Renovation and Remodeling Carpentry
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Crown Molding and Baseboards
                          </li>
                        </ul>
                      </div>
                      <a href="/services/services-details" className="btn">
                        Book Service
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane ${tab === 3 ? "active show" : ""}`}
                  id="engineer-tab-pane"
                  role="tabpanel"
                  aria-labelledby="engineer-tab"
                  tabIndex="0"
                >
                  <div className="services-item-three">
                    <div className="services-thumb-three">
                      <img
                        src={require(`../../assets/img/services/services3.webp`)}
                        alt=""
                      />
                    </div>
                    <div className="services-content-three">
                      <div className="section-title mb-30">
                        <h2 className="title">
                        Comprehensive Drywall Services: Installation, Repair, and Finishing

                        </h2>
                      </div>
                      <p>
                      Our drywall services provide quality installation, repair, and finishing. 
                      Trust our experts to enhance any residential or commercial space with precision and care.
                      </p>
                      <div className="services-list">
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Drywall Installation
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Drywall Repair and Patching
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Texturing and Finishing
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Ceiling Drywall Installation
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Drywall Painting Preparation
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Custom Drywall Design
                          </li>
                        </ul>
                      </div>
                      <a href="/services/services-details" className="btn">
                        Book Service
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
