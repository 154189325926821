import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';

function MainWid() {
  const form = useRef();
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_lx5400c', 'template_nbvllpe', form.current, 'FgTrq606DObPu4cSk')
      .then(
        (result) => {
          console.log('SUCCESS!', result.status, result.text);
          setResponseMessage("Quote request sent successfully!");
          setResponseType("success");
          form.current.reset();  // Reset the form fields
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setResponseMessage("An error occurred. Please try again later.");
          setResponseType("error");
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        }
      );
  };

  return (
    <section className="services-details-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="services-details-wrap">
              <div className="services-details-thumb">
                <img
                  src={require(`../../../assets/img/services/drywallbg.jpg`)}
                  alt=""
                />
              </div>
              <div className="services-details-content">
                <h2 className="title">Enhance Your Home with Professional Drywall Services</h2>
                <p>
                  At D&A Friends Painting LLC, we specialize in both interior and exterior drywall services to completely transform your home. 
                  Our skilled team ensures a flawless finish inside, creating smooth and durable walls and ceilings. Whether it's new construction, 
                  repairs, or renovations, we deliver impeccable drywall work that enhances the beauty and functionality of your living spaces. 
                  Trust us to handle all your drywall needs with professionalism and care, creating a stunning result you'll love.
                </p>
                <div className="services-process-wrap">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8">
                      <div className="services-process-img">
                        <img src={require(`../../../assets/img/services/drywall11.jpg`)} alt="" />
                        <img src={require(`../../../assets/img/services/drywall2.jpg`)} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="services-process-content">
                        <h2 className="title">Our Services</h2>
                        <ul className="list-wrap">
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img src={require(`../../../assets/img/icon/sp_icon01.svg`).default} alt="" />
                              </div>
                              <div className="content">
                                <h4 className="title">Drywall Installation</h4>
                                <p>Perfect for new construction or home additions.</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img src={require(`../../../assets/img/icon/sp_icon02.svg`).default} alt="" />
                              </div>
                              <div className="content">
                                <h4 className="title">Drywall Repair</h4>
                                <p>Restores the integrity and appearance of your walls.</p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img src={require(`../../../assets/img/icon/sp_icon01.svg`).default} alt="" />
                              </div>
                              <div className="content">
                                <h4 className="title">Drywall Finishing and Texturing</h4>
                                <p>Offers various textures to match your design preferences.</p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="title-two">Drywall Installation</h2>
                <p>
                  Ideal for new construction or home additions. We ensure precise measurement, cutting, and secure installation of drywall sheets. 
                  Our detailed process includes taping, mudding, and sanding for smooth, even walls and ceilings ready for painting or finishing.
                </p>
                <div className="service-benefits-wrap">
                  <div className="row">
                    <div className="col-lg-7 order-0 order-lg-2">
                      <div className="benefits-img">
                        <img src={require(`../../../assets/img/services/drywall3.jpg`)} alt="" />
                        <img src={require(`../../../assets/img/services/drywall4.jpg`)} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="benefits-content">
                        <h2 className="title">Drywall Finishing and Texturing</h2>
                        <p>
                          Offers a variety of finishing options for walls and ceilings. Our team applies joint compound, sands, and primes for a flawless finish. 
                          We also provide texturing techniques like knockdown, orange peel, and skip trowel to add a unique touch and prepare surfaces for painting.
                        </p>
                        <ul className="list-wrap">
                          <li><i className="fas fa-check-circle"></i>Popcorn Ceiling Removal</li>
                          <li><i className="fas fa-check-circle"></i>Soundproofing</li>
                          <li><i className="fas fa-check-circle"></i>Moisture-Resistant Drywall</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <aside className="services-sidebar">
              <div className="services-widget">
                <h4 className="widget-title">Our All Service</h4>
                <div className="our-services-list">
                  <ul className="list-wrap">
                    <li>
                      <a href="/services/services-details">
                        Interior Painting
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details">
                        Exterior Painting
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details">
                        Staining and Finishing
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details">
                        Wallpaper Removal and Installation
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details-carpentry">
                        Carpentry Services
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details-drywall">
                        Drywall Services
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="services-widget widget-bg"
                style={{
                  backgroundImage: `url(${require('../../../assets/img/services/sw_bg.jpg')})`
                }}
              >
                <h4 className="widget-title">Get a free quote</h4>
                <form ref={form} onSubmit={sendEmail} className="sidebar-form">
                  <div className="form-grp">
                    <input name="user_name" type="text" placeholder="Your Name" required />
                  </div>
                  <div className="form-grp">
                    <input name="user_email" type="email" placeholder="Your Email Address" required />
                  </div>
                  <div className="form-grp">
                    <textarea name="message" placeholder="Your Message" required></textarea>
                  </div>
                  <button type="submit" className="btn btn-two">Contact Us</button>
                  {responseMessage && (
                    <div className={`response-message ${responseType}`}>
                      {responseMessage}
                    </div>
                  )}
                </form>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
