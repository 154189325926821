import React from "react";

function Hero() {
  return (
    <section
      className="banner-area-three banner-two"
      style={{
        backgroundImage: `url(${require("../../assets/img/banner/h4_banner_bg.jpg")})`,
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="banner-content-three">
              <h2 className="title wow fadeInUp" data-wow-delay=".2s">
              Looking for Painting, Carpentry & Drywall Solutions.
              </h2>
              <p className="wow fadeInUp" data-wow-delay=".4s">
              We deliver expert painting, carpentry, and drywall services, 
              ensuring your projects are completed with precision and care.
              </p>
              <a
                href="/services"
                className="btn wow fadeInUp"
                data-wow-delay=".6s"
              >
                Discover More
              </a>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
