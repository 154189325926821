import React from "react";

function SupportArea() {
  return (
    <section className="support-area pt-115 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-md-6">
            <div className="support-item">
              <div className="support-icon">
                <img
                  src={
                    require(`../../assets/img/icon/support_icon01.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="support-content">
                <h4 className="title">Fast Project Start</h4>
                <p>
                
                We ensure a quick response time to start your project promptly.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="support-item">
              <div className="support-icon">
                <img
                  src={
                    require(`../../assets/img/icon/support_icon02.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="support-content">
                <h4 className="title">Customer Support</h4>
                <p>
                Our team provides exceptional customer support to address any questions or concerns you may have
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="support-item">
              <div className="support-icon">
                <img
                  src={
                    require(`../../assets/img/icon/support_icon03.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="support-content">
                <h4 className="title">24/7 Availability</h4>
                <p>
                We are available around the clock to meet your needs and ensure your project runs smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SupportArea;
