import React from "react";

const HistoryArea = () => (
    <section className="history-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="history-img-wrap">
              <ul className="list-wrap">
                <li>
                  <img
                    src={require(`../../assets/img/images/history_img01.png`)}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require(`../../assets/img/images/history_img02.jpg`)}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require(`../../assets/img/images/history_img03.jpg`)}
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="history-content">
              <div className="section-title mb-20">
                <span className="sub-title">Our History</span>
                <h2 className="title">
                Our Legacy of Excellence

                </h2>
              </div>
              <p>
              At D&A Friends Painting LLC in New Haven, we provide top-quality
              painting, carpentry, and drywall services. With over 10 years of experience, 
              we guarantee exceptional results and customer satisfaction.
              </p>
              <div className="history-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>Technology management
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Roofing Solutions
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Painting Solutions
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Modern Worker Working
                    here
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Quick Response
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )

export default HistoryArea;
