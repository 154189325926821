import React from "react";

function ServiceArea({ className }) {
  return (
    <section className={className || ""}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">What We Do</span>
              <h2 className="title tg-element-title">Our Services Areas</h2>
              
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                background: `url("../../assets/img/services/services_item01.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon01.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Interior Painting</a>
                </h2>
                <h2 className="number">01</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Interior Painting</a>
                </h2>
                <p>
                We provide professional interior painting services to give your home a vibrant, new look. 
                Our team uses high-quality paints and precise techniques to ensure a flawless finish.
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".4s"
              style={{
                background: `url("../../assets/img/services/services_item02.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon02.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Exterior Painting</a>
                </h2>
                <h2 className="number">02</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Exterior Painting</a>
                </h2>
                <p>
                Our exterior painting services not only enhance the curb appeal of your home 
                but also protect it from harsh weather conditions. We use durable paints and materials to ensure long-lasting results.
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                background: `url("../../assets/img/services/services_item03.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon03.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Staining and Finishing</a>
                </h2>
                <h2 className="number">03</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Staining and Finishing</a>
                </h2>
                <p>
                We offer expert staining and finishing services for all types of wood surfaces. 
                Whether it's for furniture, cabinets, or decks, our team will bring out the natural beauty of the wood while providing protection.
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".3s"
              style={{
                background: `url("../../assets/img/services/services_item04.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon04.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details">Wallpaper R & I</a>
                </h2>
                <h2 className="number">04</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details">Wallpaper Removal and Installation</a>
                </h2>
                <p>
                Our team can efficiently remove old wallpaper and install 
                new designs to refresh any room. We handle all types of wallpaper and ensure a smooth, clean finish.
                </p>
                <a href="/services/services-details" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".6s"
              style={{
                background: `url("../../assets/img/services/services_item05.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon05.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details-carpentry">Carpentry Services</a>
                </h2>
                <h2 className="number">05</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details-carpentry">Carpentry Services</a>
                </h2>
                <p>
                We offer a wide range of carpentry services, including custom builds, installations, and repairs. 
                Our skilled carpenters can create and fix anything from cabinets and shelves to trim and moldings.
                </p>
                <a href="/services/services-details-carpentry" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div
              className="services-item wow fadeInUp"
              data-wow-delay=".9s"
              style={{
                background: `url("../../assets/img/services/services_item06.jpg")`,
              }}
            >
              <div className="services-icon">
                <img
                  src={
                    require(`../../assets/img/icon/services_icon06.svg`).default
                  }
                  alt=""
                />
              </div>
              <div className="services-content">
                <h2 className="title">
                  <a href="/services/services-details-drywall">Drywall Services</a>
                </h2>
                <h2 className="number">06</h2>
              </div>
              <div className="services-overlay-content">
                <h2 className="title">
                  <a href="/services/services-details-drywall">Drywall Services</a>
                </h2>
                <p>
                Our drywall services include everything from installation to repair and finishing. We ensure a smooth, 
                even surface ready for painting or wallpapering, taking care of every detail to achieve the best results.
                </p>
                <a href="/services/services-details-drywall" className="read-more">
                  Read More <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
