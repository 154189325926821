import { Route, Routes } from "react-router-dom";
import HomeFour from "./Components/HomeFour"; // Asegúrate de que la ruta de importación sea correcta
import About from "./Components/About";
import Contact from "./Components/Contact";
import Blogs from "./Components/Blogs";
import Blog from "./Components/Blogs/Blog";
import Team from "./Components/Team";
import TeamDetails from "./Components/TeamDetails";
import Error from "./Components/Error";
import Services from "./Components/Services";
import ServiceDetails from "./Components/Services/ServiceDetails";
import ServiceDetailsCarpentry from "./Components/Services/ServiceDetailsCarpentry";
import ServiceDetailsDrywall from "./Components/Services/ServiceDetailsDrywall";
import Project from "./Components/Project";
import ProjectDetails from "./Components/Project/ProjectDetails";
import Gallery from "./Components/Gallery"; // Nueva importación

function Routers() {
    return (
        <Routes>
            <Route exact path="/" element={<HomeFour />} />
            <Route exact path="/home-four" element={<HomeFour />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/blogs/blog" element={<Blog />} />
            <Route exact path="/team" element={<Team />} />
            <Route exact path="/team-details" element={<TeamDetails />} />
            <Route exact path="/error" element={<Error />} />
            <Route exact path="/project" element={<Project />} />
            <Route exact path="/project-details" element={<ProjectDetails />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/services/services-details" element={<ServiceDetails />} />
            <Route
                exact
                path="/services/services-details-carpentry"
                element={<ServiceDetailsCarpentry />}
            />
            <Route
                exact
                path="/services/services-details-drywall"
                element={<ServiceDetailsDrywall />}
            />
            <Route exact path="/gallery" element={<Gallery />} /> {/* Nueva ruta */}
        </Routes>
    );
}

export default Routers;
